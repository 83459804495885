/* all routes */
// const AdminLogin = () => import('../components/Admin/login')
const AdminLayout = () => import("../layouts/AdminLayout")
const Dashboard = () => import("../views/Admin/Dashboard")

// Admin actvity
const AdminActivityLogList = () => import("../views/Admin/AdminActivityLog/AdminActivityLogList.vue")
const AdminActivityLogView = () => import("../views/Admin/AdminActivityLog/AdminActivityLogView")

// Career
const CareerList = () => import("../views/Admin/Career/CareerList.vue")
const CareerEdit = () => import("../views/Gide/ListPages/Career/CareerEdit.vue")

// eventTypeList
const eventTypeList = () => import("../views/Admin/Event/EventTypeList.vue")
const eventTypeAdd = () => import("../views/Admin/Event/EventTypeAdd.vue")
const eventTypeEdit = () => import("../views/Admin/Event/EventTypeEdit.vue")

// Event Level
const eventLevelList = () => import("../views/Admin/Event/EventLevelList.vue")
const eventLevelAdd = () => import("../views/Admin/Event/EventLevelAdd.vue")
const eventLevelEdit = () => import("../views/Admin/Event/EventLevelEdit.vue")

// post
const PostList = () => import("../views/Admin/Post/PostList.vue")
const PostAdd = () => import("../views/Admin/Post/PostAdd.vue")
const PostEdit = () => import("../views/Admin/Post/PostEdit.vue")

// Career Opportunity
const CareerOpportunityList = () => import("../views/Admin/Career/CareerOpportunityList.vue")
const CareerOpportunityAdd = () => import("../views/Admin/Career/CareerOpportunityAdd.vue")
const CareerOpportunityEdit = () => import("../views/Admin/Career/CareerOpportunityEdit.vue")

// Career Exam
const CareerExamList = () => import("../views/Admin/Career/CareerExamList.vue")
const CareerExamAdd = () => import("../views/Admin/Career/CareerExamAdd.vue")
const CareerExamEdit = () => import("../views/Admin/Career/CareerExamEdit.vue")

// career course
const CareerCourseList = () => import("../views/Admin/Career/CareerCourseList.vue")
const CareerCourseAdd = () => import("../views/Admin/Career/CareerCourseAdd.vue")
const CareerCourseEdit = () => import("../views/Admin/Career/CareerCourseEdit.vue")

// career Pba
const CareerPbaList = () => import("../views/Admin/Career/CareerPbaList.vue")
const CareerPbaAdd = () => import("../views/Admin/Career/CareerPbaAdd.vue")
const CareerPbaEdit = () => import("../views/Admin/Career/CareerPbaEdit.vue")

// career category
const CareerCategoryList = () => import("../views/Admin/Career/CareerCategoryList.vue")
const CareerCategoryAdd = () => import("../views/Admin/Career/CareerCategoryAdd.vue")
const CareerCategoryEdit = () => import("../views/Admin/Career/CareerCategoryEdit.vue")

// rating
const RatingList = () => import("../views/Admin/Rating/RatingList")

// Games_hm
const HangmanAdd = () => import("../views/Admin/Hangman/HangmanAdd.vue")
const HangmanEdit = () => import("../views/Admin/Hangman/HangmanEdit.vue")
const HangmanList = () => import("../views/Admin/Hangman/HangmanList.vue")

// Game_cw
const CrosswordAdd = () => import("../views/Admin/CrossWord/CrosswordAdd.vue")
const CrosswordEdit = () => import("../views/Admin/CrossWord/CrosswordEdit.vue")
const CrosswordList = () => import("../views/Admin/CrossWord/CrosswordList.vue")

// Loyalty
const UserLoyaltyList = () => import("../views/Admin/UserLoyalty/UserLoyaltyList")
const UserLoyaltyAdd = () => import("../views/Admin/UserLoyalty/UserLoyaltyAdd")
const UserLoyaltyEdit = () => import("../views/Admin/UserLoyalty/UserLoyaltyEdit")

// SPCM Manage Counsellors
const spcmManageCounsellors = () => import("../views/Admin/Spcm/SpcmCounsellorList.vue")

// SPCM Session
const SpcmSessionList = () => import("../views/Admin/Spcm/SpcmSessionList.vue")
const SpcmSessionEdit = () => import("../views/Admin/Spcm/SpcmSessionEdit.vue")

// SPCM Calender
// const SpcmCalenderAdd = () => import("../views/Admin/Spcm/SpcmCalenderAdd.vue")

const adminChildRoutes = (prop, mode) => [
  {
    path: "dashboard",
    name: prop + ".dashboard",
    meta: { requiresAuth: true, name: "Dashboard", isAdmin: true },
    component: Dashboard
  },
  {
    path: "adminActivityLog_list",
    name: prop + ".adminActivityLog_list",
    meta: { auth: true, name: "AdminActivityLog List", isAdmin: true },
    component: AdminActivityLogList
  },
  {
    path: "/admin/adminActivityLog_view/:user_id",
    name: prop + ".adminActivityLog_view",
    meta: { auth: true, name: "View AdminActivityLog", isAdmin: true },
    component: AdminActivityLogView
  },
  {
    path: "career_list",
    name: prop + ".career_list",
    meta: { auth: true, name: "Career List", isAdmin: true },
    component: CareerList
  },
  {
    path: "career_edit/:carr_id",
    name: prop + ".career_edit",
    meta: { auth: true, name: "Edit Career", isAdmin: true },
    component: CareerEdit
  },
  {
    path: "careerOpportunity_list",
    name: prop + ".careerOpportunity_list",
    meta: { auth: true, name: "CareerOpportunity List", isAdmin: true },
    component: CareerOpportunityList
  },
  {
    path: "careerOpportunity_add",
    name: prop + ".careerOpportunity_add",
    meta: { auth: true, name: "Add CareerOpportunity", isAdmin: true },
    component: CareerOpportunityAdd
  },
  {
    path: "careerOpportunity_edit/:carr_opp_id",
    name: prop + ".careerOpportunity_edit",
    meta: { auth: true, name: "Edit CareerOpportunity", isAdmin: true },
    component: CareerOpportunityEdit
  },
  {
    path: "careerExam_list",
    name: prop + ".careerExam_list",
    meta: { auth: true, name: "CareerExam List", isAdmin: true },
    component: CareerExamList
  },
  {
    path: "careerExam_add",
    name: prop + ".careerExam_add",
    meta: { auth: true, name: "Add CareerExam", isAdmin: true },
    component: CareerExamAdd
  },
  {
    path: "careerExam_edit/:carr_exam_id",
    name: prop + ".careerExam_edit",
    meta: { auth: true, name: "Edit CareerExam", isAdmin: true },
    component: CareerExamEdit
  },
  {
    path: "careerCourse_list",
    name: prop + ".careerCourse_list",
    meta: { auth: true, name: "CareerCourse List", isAdmin: true },
    component: CareerCourseList
  },
  {
    path: "careerCourse_add",
    name: prop + ".careerCourse_add",
    meta: { auth: true, name: "Add CareerCourse", isAdmin: true },
    component: CareerCourseAdd
  },
  {
    path: "careerCourse_edit/:carr_course_id",
    name: prop + ".careerCourse_edit",
    meta: { auth: true, name: "Edit CareerCourse", isAdmin: true },
    component: CareerCourseEdit
  },
  {
    path: "careerPba_list",
    name: prop + ".careerPba_list",
    meta: { auth: true, name: "CareerPba List", isAdmin: true },
    component: CareerPbaList
  },
  {
    path: "careerPba_add",
    name: prop + ".careerPba_add",
    meta: { auth: true, name: "Add CareerPba", isAdmin: true },
    component: CareerPbaAdd
  },
  {
    path: "careerPba_edit/:carr_pba_id",
    name: prop + ".careerPba_edit",
    meta: { auth: true, name: "Edit CareerPba", isAdmin: true },
    component: CareerPbaEdit
  },
  {
    path: "careerCategory_list",
    name: prop + ".careerCategory_list",
    meta: { auth: true, name: "CareerCategory List", isAdmin: true },
    component: CareerCategoryList
  },
  {
    path: "careerCategory_add",
    name: prop + ".careerCategory_add",
    meta: { auth: true, name: "Add CareerCategory", isAdmin: true },
    component: CareerCategoryAdd
  },
  {
    path: "careerCategory_edit/:carr_cat_id",
    name: prop + ".careerCategory_edit",
    meta: { auth: true, name: "Edit CareerCategory", isAdmin: true },
    component: CareerCategoryEdit
  },
  {
    path: "eventType_list",
    name: prop + ".eventType_list",
    meta: { auth: true, name: "Event Type List", isAdmin: true },
    component: eventTypeList
  },
  {
    path: "eventType_add",
    name: prop + ".eventType_add",
    meta: { auth: true, name: "Add Event Type", isAdmin: true },
    component: eventTypeAdd
  },
  {
    path: "eventType_edit/:event_type_id",
    name: prop + ".eventType_edit",
    meta: { auth: true, name: "Edit Event Type", isAdmin: true },
    component: eventTypeEdit
  },
  {
    path: "eventLevel_list",
    name: prop + ".eventLevel_list",
    meta: { auth: true, name: "Event Level List", isAdmin: true },
    component: eventLevelList
  },
  {
    path: "eventLevel_add",
    name: prop + ".eventLevel_add",
    meta: { auth: true, name: "Add Event Level", isAdmin: true },
    component: eventLevelAdd
  },
  {
    path: "eventLevel_edit/:event_lev_id",
    name: prop + ".eventLevel_edit",
    meta: { auth: true, name: "Edit Event Level", isAdmin: true },
    component: eventLevelEdit
  },
  {
    path: "post_list",
    name: prop + ".post_list",
    meta: { auth: true, name: "Post List", isAdmin: true },
    component: PostList
  },
  {
    path: "post_add",
    name: prop + ".post_add",
    meta: { auth: true, name: "Add Post", isAdmin: true },
    component: PostAdd
  },
  {
    path: "post_edit/:post_id",
    name: prop + ".post_edit",
    meta: { auth: true, name: "Edit Post", isAdmin: true },
    component: PostEdit
  },
  {
    path: "rating_list",
    name: prop + ".rating_list",
    meta: { auth: true, name: "Rating List", isAdmin: true },
    component: RatingList
  },
  {
    path: "/admin/game_hm_list",
    name: prop + ".game_hm_list",
    meta: { auth: true, name: "GAME_HM List" },
    component: HangmanList
  },
  {
    path: "/admin/game_hm_add",
    name: prop + ".game_hm_add",
    meta: { auth: true, name: "Add GAME_HM" },
    component: HangmanAdd
  },
  {
    path: "/admin/game_hm_edit/:game_hm_id",
    name: prop + ".game_hm_edit",
    meta: { auth: true, name: "Edit GAME_HM" },
    component: HangmanEdit
  },
  {
    path: "/admin/game_cw_list",
    name: prop + ".game_cw_list",
    meta: { auth: true, name: "GAME_CW List" },
    component: CrosswordList
  },
  {
    path: "/admin/game_cw_add",
    name: prop + ".game_cw_add",
    meta: { auth: true, name: "Add GAME_CW" },
    component: CrosswordAdd
  },
  {
    path: "/admin/game_cw_edit/:game_cw_id",
    name: prop + ".game_cw_edit",
    meta: { auth: true, name: "Edit GAME_CW" },
    component: CrosswordEdit
  },
  {
    path: "/admin/user_loyalty_list",
    name: prop + ".user_loyalty_list",
    meta: { auth: true, name: "UserLoyalty List" },
    component: UserLoyaltyList
  },
  {
    path: "/admin/user_loyalty_add",
    name: prop + ".user_loyalty_add",
    meta: { auth: true, name: "Add UserLoyalty" },
    component: UserLoyaltyAdd
  },
  {
    path: "/admin/user_loyalty_edit/:loy_id",
    name: prop + ".user_loyalty_edit",
    meta: { auth: true, name: "Edit UserLoyalty" },
    component: UserLoyaltyEdit
  },
  {
    path: "/admin/spcmSession_list",
    name: prop + ".spcmSession_list",
    meta: { auth: true, name: "SpcmSession List" },
    component: SpcmSessionList
  },
  {
    path: "/admin/spcmSession_edit/:session_id",
    name: prop + ".spcmSession_edit",
    meta: { auth: true, name: "Edit SpcmSession" },
    component: SpcmSessionEdit
  },
  {
    path: "/admin/spcmManage_counsellors",
    name: prop + ".spcm_manage_counsellors",
    meta: { auth: true, name: "Manage SpcmCounsellors" },
    component: spcmManageCounsellors
  }
]

export default [
  {
    path: "/admin",
    name: "admin",
    component: AdminLayout,
    meta: { requiresAuth: true, isAdmin: true },
    children: adminChildRoutes("admin")
  }
]
